/*======================================================
MEDIACENTRE_PRINT.CSS
======================================================

1. HIDDEN STYLES
- Homepage
- Interior
2. STRUCTURE
- Font
- Links
- Images
- Tables
- iCreate Classes
- Default Styles
- Breadcrumbs
3. DEFAULT PAGE SETUP
- Logo
- Top Nav
4. NEWS/BLOG
5. ACCORDIONS
6. SOCIAL MEDIA

===================================================== */

@media print {

	// Reset print styles for high contrast
	*,
	*:before,
	*:after {
		color: black !important;
		background: transparent !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	/*======================================================
	1. HIDDEN STYLES
	===================================================== */

	.toast,
	#calendar-popover-title,
	#skipContentWrapper,
	#topNav,
	.mobileNav,
	.searchContainer,
	#idLikeTo,
	.mainNavContainer,
	#mainNav,
	.mobileDropDown,
	.mobileSearchContainer,
	#quickLinksContainer,
	#footerNav a,
	.socialLinks,
	#embeddedEmailContainer,
	#EmergencyBannerWrapperOuter,
	#BrowserBannerWrapperOuter,
	#tableStyles,
	#languageContainer,
	#__ba_panel,
	.footerNavMega,
	.footerBottomRight,
	#translateContainer,
	#atozBar,
	#socialWrapper.feedbackModal,
	.ui-dialog,
	.tableContainerHead,
	.tableContainer,
	.iframe-container-wrapper,
	#ytTranslate,
	header h1,
	.errorpage h1,
	.imageScrollerOuter,
	.slider-container,
	.tickerContentOuter,
	#quickLinks,
	.mapLocator,
	#calendar,
	#quickLinks,
	#photoGalleries,
	.eSolutionsGroupPhotoGalleryV2PlaceholderDiv,
	#intBgImage,
	#subNavWrapper,
	#subNavContainer,
	#intBanner,
	.subNavTriggerContainer,
	.subNavTrigger,
	#actions,
	.toggleContent,
	#pageSubscription,
	.contrastSwitcher,
	.icEmergencyModal,
	.accordionButtons,
	.emergencyBarSlider,
	.topNavQuicklinksContainer,
	#idLikeToModal,
	.icPagination,
	.modal-box,
	#topNavContainer,
	.footerLogo,
	#stickyHeader,
	.socialMediaButtons .slick-controls,
	.footerBottom .footerNav {
		display: none !important;
	}

	#uber {
		padding-top: 0 !important;
	}

	#esol,
	.AccordionContent {
		display: block !important;
	}


	//How to
	.icHowto {
		margin-bottom: 40px;
		counter-reset: step;

		>h2 {
			margin-bottom: 5px;
			font-weight: bold;
		}

		.totalTimeRow {
			font-size: inherit;

			&:before {
				font-weight: bold;
				content: 'Total time: ';
			}
		}

		.toolsResourcesRow {
			font-size: inherit;

			&:before {
				font-weight: bold;
				content: 'Tools/resources: ';
			}
		}

		.stepRow {
			position: relative;
			display: list-item;
			margin-left: 50px;
			list-style-type: none;
			page-break-inside: avoid;

			&:before {
				// content: attr(data-num);
				position: absolute;
				top: 2px;
				left: -50px;
				display: flex;
				width: 32px;
				height: 32px;
				font-size: 20px;
				font-weight: bold;
				border: 3px solid #000;
				border-radius: 32px;
				align-items: center;
				counter-increment: step;
				content: counter(step);
				content: counter(step) / ""; //alternate text for supported screen readers				
				justify-content: center;
			}

			>div:nth-child(1) {
				margin-bottom: 10px;
				font-weight: bold;
			}

			>div:nth-child(2) {
				margin-bottom: 18px;
				padding-bottom: 22px;
			}
		}
	}

	/*======================================================
	2. STRUCTURE
	===================================================== */

	header {
		padding-bottom: 30px;
	}

	#logo {
		height: auto !important;

		img {
			max-width: 250px;
			max-height: 100px; //If the logo is SVG it needs a width and height specified
		}

		p {
			display: inline-block;
		}
	}


	footer {
		margin-top: 30px;
		padding: 30px 0 20px 0;
		clear: both;
		border-top: solid 1px #999;

		img {
			max-width: 200px;
		}

		.footerlogo {
			p {
				display: inline-block;
			}

			.secondaryLogo {
				a {
					img {
						height: 96px;
					}

					&:last-child {
						img {
							height: 55px;
						}
					}
				}

			}
		}
	}

	.footerTop {
		min-height: 0 !important;
	}

	#contactMainContainer {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: solid 1px #ccc;
	}

	.box {
		margin: 10px 0;
	}

	.contactHeader {
		font-size: 18pt;
		line-height: 1.3;
	}

	#mapWrapper {
		margin-top: 30px;
		padding: 30px 0 20px 0;
		clear: both;
		border-top: solid 1px #999;
	}

	.iframe-container {
		padding-bottom: 0 !important;
	}

	.lb-carousel {
		.flickity-viewport {
			height: auto !important;
		}

		.flickity-slider,
		.lb-carouselCell {
			position: static !important;
			transform: none !important;
		}

		.flickity-prev-next-button {
			display: none;
		}
	}

	.lb-imageBox_header,
	.lb-infoCard_contentWrapper,
	.lb-callToAction_contentWrapper {
		height: auto !important;
	}

	.siteMapSectionHeading {

		a,
		a:active,
		a:visited {
			color: #000 !important;
		}
	}

	.socialMediaWrapper {
		.slick-track {
			width: 100% !important;
		}

		.twitterItem {
			margin-bottom: 20px;
			border-bottom: 1px solid #ddd;
		}
	}


	/*======================================================
	3. DEFAULT PAGE SETUP
	===================================================== */

	#uber {
		margin: 0.5cm;
	}

	/* ====== FONT */

	body {
		min-height: 0 !important;
		font-family: Verdana, "Helvetica Neue", Helvetica, Arial, Sans-Serif;
		font-size: 12pt;
		line-height: 1.3;
		color: #000;
		background: #FFF;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin: 15px 0px;
		font-weight: normal;
	}

	h1 {
		margin-bottom: 30px;
		padding-bottom: 10px;
		font-size: 24pt;
		border-bottom: 1px solid #ccc;

		.headingFlexContainer & {
			margin: 0;
			border-bottom: 0;
		}
	}

	h2,
	caption {
		font-size: 21pt;
	}

	h3 {
		font-size: 19pt;
	}

	h4 {
		font-size: 17pt;
	}

	h5 {
		font-size: 15pt;
	}

	h6 {
		font-size: 14pt;
	}

	caption {
		padding: 15px 0;
		font-weight: normal;
		text-align: left;
		page-break-inside: avoid;
		page-break-after: auto;
	}

	/* ====== LINKS */

	a {
		color: #000;
		text-decoration: underline;
		word-wrap: break-word;
	}

	#ghd a:after,
	a#ghd:after {
		font-size: 70%;
		content: " ("attr(href) ")";
	}

	/* ====== IMAGES */

	img {
		max-width: 500px;
		max-height: 200px;
		border: 0px;
	}

	.mapImage img {
		max-width: 80%;
	}

	img.ImageLeft,
	img.imageLeft,
	img.Left,
	.Left iframe {
		float: left;
		margin: 0 20px 20px 0;
		padding: 1px;
	}

	img.ImageRight,
	img.imageRight,
	img.Right,
	.Right iframe {
		float: right;
		margin: 0 0 20px 20px;
		padding: 1px;
	}

	img.Center {
		display: block;
		margin-left: auto;
		margin-right: auto;
		clear: both;
	}

	/* ====== TABLES */

	table,
	th,
	td {
		border: 1px solid #ccc;
		border-collapse: collapse;
	}

	table {
		width: 100% !important;
		margin: 15px 0;
		table-layout: fixed;
	}

	td {
		padding: 5px 10px;
	}

	th,
	.titlerow {
		font-weight: bold;

		p {
			font-weight: bold;
		}
	}

	/* ====== iCREATE CLASSES */

	.Center,
	.alignCenter {
		text-align: center;
	}

	.Left,
	.alignLeft {
		text-align: left;
	}

	.Right,
	.alignRight {
		text-align: right;
	}

	/* ====== DEFAULT STYLES */

	#contentLeft ol,
	#printArea ol {
		margin: 15px 0px 30px 30px;
	}

	#contentLeft ul,
	#printArea ul {
		margin: 15px 0px 30px 30px;
	}

	#contentLeft li,
	#printArea li {
		margin: 10px 0px;
	}

	video,
	audio,
	embed,
	object {
		display: none;
	}

	sub {
		font-size: 80%;
	}

	sup {
		font-size: 80%;
	}

	hr {
		display: block;
		height: 1px;
		margin: 20px 0;
		color: #ccc;
		background-color: #ccc;
		border: none;
	}

	/* ====== BREADCRUMBS */

	#breadcrumbs {
		margin: 0 0 30px 0;
		padding: 10px 0;
		font-size: 16px;
		font-weight: normal;
		color: #000;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;

		a {
			text-decoration: none;

			&:after {
				display: inline-block;
				margin-right: 10px;
				margin-left: 10px;
				text-decoration: none;
				content: "/";
			}
		}
	}

	/*======================================================
	4. NEWS
	===================================================== */

	.backToSearchWrapper,
	.newsContainer_Months,
	#newsContainer_Pagelist,
	.newsItem_social,
	#blogLoginModal,
	#blogComments,
	.readmoreWrapper,
	#blogPagination,
	#blogNavWrapper,
	.newsSubscribeContainer img {
		display: none;
	}

	.newsItem_Header {
		clear: both;
		width: 100%;
	}

	.newsItem {
		display: block !important;
		padding: 0 0 10px 0;
		clear: both;
	}

	.newsCategoryTitle h1 {
		position: relative !important;
		top: auto !important;
		left: auto !important;
		width: 100% !important;
		padding-right: 0 !important;
	}

	/*======================================================
	5. ACCORDIONS
	===================================================== */

	.AccordionTrigger {
		margin: 30px 0 15px 0;
		padding: 0 15px;
		border: 1px solid #ccc;

		* {
			font-size: 12pt;
			font-weight: bold;
		}

		a {
			text-decoration: none;
		}
	}

	.AccordionContent {
		padding: 0 16px;
	}

	.Accordion {
		margin-bottom: 30px;
	}

	// News V3

	.blogFeed {

		.blogPostCategory,
		.blogPostTag {
			margin-top: 1rem;

			a {
				text-decoration: none;

				&:after {
					content: ', ';
				}

				&:last-of-type {
					&:after {
						content: '';
					}
				}
			}

			p {
				margin-top: 0;
			}
		}

		.blogPostCategory {
			&:before {
				content: 'Categories: ';
			}
		}

		.blogPostTag {
			&:before {
				content: 'Tags: ';
			}
		}
	}


	/*======================================================
	6. Layout Builder
	===================================================== */

	.fbg-row.lmRow.ui-sortable {
		display: block;
		margin-bottom: 10px;
	}

	.lb-callToAction_contentWrapper {
		height: auto !important;
		margin-bottom: 1em;
	}

	.lb-imageBox_header {
		height: auto !important;
	}

}